import './App.css';
import './styles/styles.scss'
import Home from "./home/Home"

function App() {
    return (

        <>
            <Home/>
        </>
    );
}

export default App;
