import React from 'react';

const Flasher = () => {
    return (
        <div>
            <div className="flasher">
                <div className="Flasher"></div>
                <div className="Flasher2"></div>
            </div>
        </div>
    );
};

export default Flasher;